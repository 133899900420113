import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginTop: '2rem',
  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    flexDirection: 'column',
  },
}));

export default Wrapper;
