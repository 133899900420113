import ContentBlock from './primitives/ContentBlock';
import H2 from 'primitives/Typography/Headings/H2';
import MediaServerImage from 'components/MediaServerImage';
import NavLink from 'components/NavLink';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import WelcomeSection from 'components/WelcomeSection';
import Wrapper from './primitives/Wrapper';
import { Event } from 'state/Events/types';
import { get } from 'lodash-es';

export type Props = {
  events: Array<Event>;
  homepageEventsSection: boolean;
};

function EventsSection({ events = [], homepageEventsSection }: Props) {
  const translate = useTranslate();

  if (events.length === 0 || !homepageEventsSection) return null;

  return (
    <WelcomeSection>
      <H2 data-test="events-header">{translate('Upcoming Events')}</H2>
      <Wrapper>
        {events.map(event => {
          const title = get(event, 'title');
          const imgUrl = get(event, 'img_uri');
          const linkUrl = get(event, ['link', 'urls', 'web']);

          return (
            <ContentBlock key={title}>
              <NavLink target="_blank" to={linkUrl}>
                <MediaServerImage alt={title} aspectRatio={1.77} src={imgUrl} />
              </NavLink>
            </ContentBlock>
          );
        })}
      </Wrapper>
    </WelcomeSection>
  );
}

export default EventsSection;
