import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const ContentBlock = styled('div')(({ theme }) => ({
  width: '49.5%',

  '.image': {
    overflow: 'hidden',
  },

  a: {
    cursor: 'pointer',
  },

  img: {
    height: '28rem',
    objectFit: 'cover',
    width: 'fit-content',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    marginBottom: '1rem',
    width: '100%',

    img: {
      height: 'auto',
    },
  },
}));

export default ContentBlock;
